<template>
  <div class="fluid container">
    <div class="col-md-3">

      <draggable class="list-group" tag="ul" :list="shopCategories" v-bind="dragOptions" :move="onMove"
                 @start="isDragging=true"
                 @end="isDragging=false" @change="changeElementOrder">
        <transition-group type="transition" :name="'flip-list'">
          <li class="list-group-item" v-for="element in shopCategories" :key="element.ord">
            <em class="menu-icon far flaticon2-sort"></em>
            {{ element.name }}
            <v-avatar
                color="grey"
                size="18"
            >
              <span class="ordinal-number">{{ element.ord }}</span>
            </v-avatar>
          </li>
        </transition-group>

      </draggable>
    </div>
    <div class="col-md-3">
      <v-btn
          color="primary"
          class="mr-4"
          @click="save()"
          :disabled="saving"
          :loading="saving"
      >
        Save
        <template v-slot:loader>
        <span class="custom-loader">
          <v-icon light>mdi-cached</v-icon>
        </span>
        </template>
      </v-btn>
      <v-btn
          @click="previousState()"
      >
        cancel
      </v-btn>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import draggable from 'vuedraggable'

export default {
  name: "shopCategories",
  data() {
    return {
      saving: false,
      loader: null,
      shopCategories: [],
      totalShopCategories: null,
      errors: []
    };
  },
  watch: {
    pagination: {
      handler() {
        this.retrieveShopCategories();
      },
      deep: true
    },
    loader () {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 3000)

      this.loader = null
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        ghostClass: "ghost"
      };
    },
    listString() {
      return JSON.stringify(this.shopCategories, null, 2);
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Shop Categories"}]);
  },
  created() {
    this.retrieveShopCategories();
  },
  components: {
    draggable
  },
  methods: {
    previousState() {
      this.$router.go(-1);
    },
    changeElementOrder: function (evt) {
      const oldIndex = evt.moved.oldIndex;
      const newIndex = evt.moved.newIndex;

      let i = Math.min(oldIndex, newIndex);
      const max = Math.max(oldIndex, newIndex) + 1;
      for (i; i < max; i += 1) {
        this.shopCategories[i].ord = i + 1;
      }
    },
    onMove({relatedContext, draggedContext}) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
          (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    orderList() {
      this.shopCategories = this.shopCategories.sort((one, two) => {
        return one.order - two.order;
      });
    },
    save() {
      this.saving = true;
      this.loader = true;
      this.$log.debug("Shop Categories new order: ", this.shopCategories);
      ApiService.post(`/api/shop-category/reorder`, this.shopCategories)
          .then(response => {
            this.$log.debug("Shop categories order updated: " + response);
          })
          .catch(error => {
            this.$log.error("Error while saving shop categories order: ", error);
          })
          .finally(() => {
            this.saving = false;
            this.loader = null;
            this.previousState();
          });
    },
    retrieveShopCategories() {
      return new Promise(resolve => {
        this.loading = true;
        ApiService.query("api/shop-category").then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("Shop Categories: ", response.data);
          this.shopCategories = response.data;
          this.totalShopCategories = response.data.length;
          this.loading = false;
          let title =
              this.totalShopCategories != null
                  ? this.totalShopCategories
                  : 0;
          this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Shop categories: " + title}
          ]);
          resolve();
        });
      });
    }
  }
};
</script>

<style>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group-item {
  cursor: move;
  padding: 0px 5px;
}

.list-group-item i {
  cursor: pointer;
}

.ordinal-number {
  font-size: 12px;
  color: white;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
